const header = document.querySelector(".header");
const btnYes = document.querySelector(".button--yes");
const btnNo = document.querySelector(".button--no");
const mainElement = document.querySelector(".main");
const picture = document.querySelector(".picture");
const text = document.querySelector(".text");

btnYes.addEventListener("click", function () {
  picture.src =
    "https://shipy4karu.github.io/Funny-request-to-slave/img/loveYou.gif";
  btnYes.disabled = "true";
  btnNo.disabled = "true";
  text.style.opacity = "1";
});

btnNo.addEventListener("mouseover", function () {
  setTimeout(function () {
    btnNo.style.position = "absolute";
    const maxX = window.innerWidth - btnNo.offsetWidth;
    const maxY = window.innerHeight - btnNo.offsetHeight;
    const newX = Math.floor(Math.random() * (maxX - 0 + 1)) + 0;
    const newY = Math.floor(Math.random() * (maxY - 0 + 1)) + 0;
    btnNo.style.top = newY + "px";
    btnNo.style.left = newX + "px";
  }, 150);
});

btnNo.addEventListener("click", function () {
  picture.src =
    "https://shipy4karu.github.io/Funny-request-to-slave/img/imOffended.gif";
  btnNo.disabled = "true";
  btnNo.style.backgroundColor = "transparent";
});
